












import { Component, Vue, Prop } from 'vue-property-decorator';

import ItemRedaction from '../ItemRedaction/ItemRedaction.vue';

import {
  MyRedactions as IMyRedactions,
} from '../../interfaces/IRedactionPreview';

@Component({
  components: {
    ItemRedaction,
  },
})
export default class ListItemRedaction extends Vue {
  @Prop({ required: true }) listRedaction!: Array<IMyRedactions>;
}
