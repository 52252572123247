





















import { Component, Vue, Prop } from 'vue-property-decorator';

import {
  Redaction as IRedaction,
} from '../../../../interfaces/IRedactionPreview';

@Component({
  components: {},
})
export default class ObservationRedaction extends Vue {
  @Prop({ required: true }) redaction!: IRedaction;

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  hasContentHTML(content: string) {
    return content === '<p><br></p>' ? false : !!content;
  }
}
