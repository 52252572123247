



































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import Footer from '@/components/Footer/Footer.vue';

import CorrectionRedactionLoading from './CorrectionRedactionLoading.vue';
import HeaderCorrection from './components/HeaderCorrection/index.vue';
import ObservationRedaction from './components/ObservationRedaction/index.vue';
import ContentRedaction from './components/ContentRedaction/index.vue';
import ListItemRedaction from '../../components/ListItemRedaction/index.vue';
import ListItemRedactionLoading from '../../components/ListItemRedaction/ListItemRedactionLoading.vue';
import LineChartAll from '@/components/Graphics/LineChartAll.vue';

import RedactionService from '@/services/Redaction/RedactionService';

import {
  Redaction as IRedaction,
  MyRedactions as IMyRedactions,
} from '../../interfaces/IRedactionPreview';

@Component({
  components: {
    PageHeader,
    BoxContainer,
    ContainerFluid,
    CorrectionRedactionLoading,
    LineChartAll,
    HeaderCorrection,
    ObservationRedaction,
    ContentRedaction,
    ListItemRedaction,
    ListItemRedactionLoading,
    FeedbackUser,
    Footer,
  },
})
export default class CorrectionRedaction extends Vue {
  private isActiveCorrection = true;
  private isLoadingOtherRedactions = true;
  private isLoadingRedaction = true;

  private list_other_redactions: Array<IMyRedactions> = [];
  private redaction!: IRedaction | null;

  private RedactionService = new RedactionService();

  mounted() {
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  @Watch('$route.params.redactionId', {
    immediate: true,
  })
  async loadData() {
    this.getOtherRedactions();
    this.getRedaction();

    this.isActiveCorrection = true;
  }

  async getOtherRedactions() {
    try {
      this.isLoadingOtherRedactions = true;
      const { redactionId } = this.$route.params;

      const response = await this.RedactionService.getMyRedaction({ page: 0, status: [1] });

      const redactions = response?.redactions;

      if (redactions.length) this.list_other_redactions = redactions.filter((redaction: any) => redaction.id !== Number(redactionId));
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar outras redações.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoadingOtherRedactions = false;
    }
  }

  async getRedaction() {
    try {
      this.isLoadingRedaction = true;
      const { redactionId } = this.$route.params;
      this.redaction = null;

      const response = await this.RedactionService.getCorrectionRedaction(Number(redactionId));

      this.redaction = response || null;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o conteúdo da redação.',
        status: 'error',
      });
      console.error(error);
    } finally {
      this.isLoadingRedaction = false;
    }
  }

  setOptionMenu(value: boolean) {
    this.isActiveCorrection = value;
  }

  remakeRedaction() {
    const themeId = String(this.redaction?.theme?.id);

    this.$router.push({
      name: 'RedactionInstruction',
      params: {
        themeId,
      },
    });
  }

  goToMyRedactions() {
    this.$router.push({
      name: 'MyRedactions',
    });
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Redação', to: '/redacao' },
      { title: 'Correção', to: null },
    ]);
  }
}
